<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">List Users</div>
    <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <div class="input-group input-group-sm custom-form-group mb-0"
        v-bind:style="[searchnamebtnactive ? { width: '330px' } : { width: '285px' }]">
        <input type="text" v-model="searchfullname" class="form-control text-capitalize"
          placeholder="Search By First Or Last Name" aria-label="Search" aria-describedby="basic-addon1"
          autocomplete="off" maxlength="30" @keypress="isLetterWithSpace($event)" @keyup.enter="searchFnameUser(searchfullname)" :disabled="hidesearchname"
          style="padding: 6px 15px;" />
        <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchfullname.length == ''"
          @click="searchFnameUser(searchfullname)" :style="{ width: '50px' }">
          <span v-if="!searchnameloader"><i class="pi pi-search" aria-hidden="true"></i></span>
          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchnameloader">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchNameUserStop()"
          :style="{ width: '50px' }" v-show="searchnamebtnactive"><i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div>
      <button type="button" @click="userfilterModalOpen()" class="header-filter-btn-outer btn btn-light" title="Filter"
        :disabled="dissableFilterButton == true">
        <span class="custom-filter-apply-alert"></span>
        <i class="pi pi-filter custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="clientUserList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
      :rows="10" :lazy="true" dataKey="ad1" :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading">
      <template #empty>No records found.</template>
      <template #loading>Loading records. Please wait...</template>
      <Column field="name" header="Name" headerStyle="width: 65%" bodyStyle="width: 65%">
        <template #body="{ data }">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div>
              <div class="font-semibold-header mb-2 text-capitalize">
                {{ data.ad9 ? data.ad9 : "N/A" }}
              </div>
              <div class="label-subheading text-capitalize" v-if="isShowMobileNo == 0">
                +91 {{ maskedNumber(data.ad15) }}
              </div>
              <div class="label-subheading text-capitalize" v-else-if="isShowMobileNo == 1">
                +91 {{ data.ad15 }}
              </div>
              <div class="label-subheading text-capitalize" v-else>
                N/A
              </div>
            </div>
            <div class="ms-3" v-if="this.isUserLockStatus == 1">
              <button type="button" title="Click To Unlock" class="btn custom-outline-lock-btn"
                @click="changelockstatus(data)">
                <span v-if="!unlockbtnloader"><i class="pi pi-lock lock-btn-color"></i></span>
                <div class="spinner-border text-bold custom-spinner-icon-loader-btn" role="status" v-if="unlockbtnloader">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </button>
            </div>
          </div>
        </template>
      </Column>
      <Column field="status" header="Status" headerStyle="width: 20%" bodyStyle="width: 20%">
        <template #body="{ data }">
          <div>
            <span v-if="data.ad44 == 1" class="status-active">Active</span>
            <span v-if="data.ad44 == 2" class="status-temporary-suspended">Temporary Suspended</span>
            <span v-if="data.ad44 == 3" class="status-permanently-suspended">Permanently Suspended</span>
          </div>
        </template>
      </Column>
      <Column field="Status" header="Action" class="justify-content-end" headerStyle="width: 15%"
        bodyStyle="width: 15%" v-if="this.isAllowUserEdit == 1">
        <template #body="{ data }">
          <button type="button" title="View Details" class="btn custom-view-detail-btn"
            @click="userDetailEditModalOpen(data)">
            Edit Details
          </button>
        </template>
      </Column>
    </DataTable>
  </div>
  <!-- Edit Details start here -->
  <div class="modal-mask" v-if="edituserdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">Edit Details</h5>
          <button type="button" class="btn-close" @click="userDetailEditModalClose()"></button>
        </div>
        <div class="px-4 pt-2">
          <div class="stepper-label-edit-voter">
            <span v-if="userEditStepFirstStatus">Step<span class="stepper-label-circle-voter">1</span></span>
            <span v-if="userEditStepTwoStatus">Step<span class="stepper-label-circle-voter">2</span></span>
            <span v-if="userEditStepThrirdStatus">Step<span class="stepper-label-circle-voter">3</span></span>
          </div>
        </div>
        <Divider />
        <!-- First Stepper Start Here -->
        <div class="modal-body modal-body-scroll-outer" v-if="userEditStepFirstStatus">
          <div style="position: relative;
    min-height: 443px;">
            <div v-if="!showloaderusermodal">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                    <div class="input-group custom-input-group">
                      <div class="col-lg-4 col-md-4 col-12">
                        <Multiselect v-model="edit.countrycode" :options="countryCodeList" :searchable="true"
                          label="label" placeholder="Select" disabled class="multiselect-custom custom-title-multiselect"
                          :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                          :canDeselect="false" />
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <input type="text" v-model="edit.primobile" class="form-control" id="formMobileNoInput" disabled
                          placeholder="Enter mobile No." maxlength="10" @keypress="onlyNumber" autocomplete="off" />
                      </div>
                    </div>
                    <div class="custom-error" v-if="v$.edit.primobile.$error">
                      {{ v$.edit.primobile.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formTitleInput" class="form-label">First Name<span class="text-danger">*</span></label>
                    <div class="input-group custom-input-group">
                      <div class="col-lg-4 col-md-4 col-12">
                        <Multiselect v-model="edit.title" :disabled="edituserloader" :options="titleList"
                          :searchable="false" label="label" placeholder="Select"
                          class="multiselect-custom custom-title-multiselect text-capitalize" :canClear="false"
                          :closeOnSelect="true" :object="true" noOptionsText="No Result" :canDeselect="false"
                          @select="handleEditGender" />
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <input type="text" v-model="edit.firstname" :disabled="edituserloader"
                          class="form-control text-capitalize" id="formFirstNameInput" placeholder="Enter First Name"
                          autocomplete="off" maxlength="20" @keypress="isLetterWithOutSpace($event)" />
                      </div>
                    </div>
                    <div class="custom-error" v-if="v$.edit.firstname.$error">
                      {{ v$.edit.firstname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLastNameInput" class="form-label">Middle Name</label>
                    <input type="text" v-model="edit.middlename" :disabled="edituserloader"
                      class="form-control text-capitalize" id="formLastNameInput" placeholder="Enter Middle Name"
                      autocomplete="off" maxlength="20" @keypress="isLetterWithOutSpace($event)" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLastNameInput" class="form-label">Last Name<span class="text-danger">*</span></label>
                    <input type="text" v-model="edit.lastname" :disabled="edituserloader"
                      class="form-control text-capitalize" id="formLastNameInput" placeholder="Enter Last Name"
                      autocomplete="off" maxlength="20" @keypress="isLetterWithOutSpace($event)" />
                    <div class="custom-error" v-if="v$.edit.lastname.$error">
                      {{ v$.edit.lastname.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formEmailInput" class="form-label">Email Id</label>
                    <input type="text" v-model="edit.email" :disabled="edituserloader" class="form-control"
                      id="formEmailInput" placeholder="Enter Email Id" autocomplete="off" maxlength="50" />
                    <div class="custom-error" v-if="v$.edit.email.$error">
                      {{ v$.edit.email.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Gender<span class="text-danger">*</span></label>
                    <Multiselect v-model="edit.gender" :disabled="edituserloader" :options="genderList"
                      :searchable="false" label="label" placeholder="Select" class="multiselect-custom" :canClear="false"
                      :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.gender.$error">
                      {{ v$.edit.gender.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Birth Date<span class="text-danger">*</span></label>
                    <Calendar v-model="edit.birthdate" :disabled="edituserloader" :maxDate="maxDateBirth" :showIcon="true"
                      class="custom-ultima-calendar" placeholder="DD/MM/YYYY" dateFormat="dd/mm/yy" :manualInput="false"
                      :monthNavigator="true" showButtonBar :yearRange="'1950:' + maxDateBirth.getFullYear()"
                      appendTo="body" :yearNavigator="true">
                    </Calendar>
                    <div class="custom-error" v-if="v$.edit.birthdate.$error">
                      {{ v$.edit.birthdate.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formEmailInput" class="form-label">Select Designation<span
                        class="text-danger">*</span></label>
                    <Multiselect v-model="edit.designation" :disabled="edituserloader" :options="designationList"
                      :searchable="true" label="label" placeholder="Search | Add Designation"
                      class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true"
                      noOptionsText="No Result Found" :object="true" :createOption="true" :addOptionOn="['enter', 'tab']"
                      @select="createVoterEditDesignation($event)" />
                    <div class="custom-error" v-if="v$.edit.designation.$error">
                      {{ v$.edit.designation.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <div class="custom-form-group">
                    <label for="formEmailInput" class="form-label d-flex justify-content-between"><span>Select Branch<span
                          class="text-danger">*</span></span><span class="small ms-1 text-muted">(Max 5)</span></label>
                    <Multiselect v-model="edit.branch" :disabled="edituserloader" :options="branchList" :searchable="true"
                      label="label" placeholder="Select" class="multiselect-custom text-capitalize" :canClear="false"
                      :closeOnSelect="true" :object="true" mode="tags" :createTag="false" :max="5"
                      noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.branch.$error">
                      {{ v$.edit.branch.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select State<span class="text-danger">*</span></label>
                    <Multiselect v-model="edit.state" :disabled="edituserloader" :options="stateList" :searchable="true"
                      label="label" placeholder="Select" class="multiselect-custom" :canClear="false"
                      :closeOnSelect="true" :object="true" noOptionsText="No Result" @keypress="isLetterWithSpace($event)"
                      @select="getCityByStateId(edit.state)" required />
                    <div class="custom-error" v-if="v$.edit.state.$error">
                      {{ v$.edit.state.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select City<span class="text-danger">*</span></label>
                    <Multiselect v-show="!cityloaderflag" :disabled="edituserloader" v-model="edit.city"
                      :options="cityList" :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                      @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                      noOptionsText="No Result" :object="true" @select="getAreaByCityId(edit.city)" required />
                    <Multiselect v-show="cityloaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.city.$error">
                      {{ v$.edit.city.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Select Area<span class="text-danger">*</span></label>
                    <Multiselect v-show="!arealoaderflag" :disabled="edituserloader" v-model="edit.area"
                      :options="areaList" :searchable="true" label="label" placeholder="Select" class="multiselect-custom"
                      @keypress="isLetterWithSpace($event)" :canClear="true" :closeOnSelect="true"
                      noOptionsText="No Result" :object="true" required />
                    <Multiselect v-show="arealoaderflag" loading :searchable="false" label="label" placeholder="Select"
                      class="multiselect-custom" noOptionsText="No Result" />
                    <div class="custom-error" v-if="v$.edit.area.$error">
                      {{ v$.edit.area.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-8 col-md-8 col-12">
                  <div class="custom-form-group">
                    <label for="formShortNameInput" class="form-label">Address<span class="text-danger">*</span></label>
                    <textarea type="text" :disabled="edituserloader" v-model="edit.fulladdress"
                      class="form-control custom-text-area-scroll" placeholder="Enter Full Address" autocomplete="off"
                      rows="2" maxlength="250"></textarea>
                    <div class="custom-error" v-if="v$.edit.fulladdress.$error">
                      {{ v$.edit.fulladdress.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Pin Code<span class="text-danger">*</span></label>
                    <input type="text" :disabled="edituserloader" v-model="edit.pincode"
                      class="form-control text-capitalize" placeholder="Enter Pin Code" maxlength="6"
                      @keypress="onlyNumber" autocomplete="off" />
                    <div class="custom-error" v-if="v$.edit.pincode.$error">
                      {{ v$.edit.pincode.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label for="formLandmarkInput" class="form-label">Nearest Landmark</label>
                    <input type="text" :disabled="edituserloader" v-model="edit.landmark"
                      class="form-control text-capitalize" id="formLandmarkInput" placeholder="Enter Landmark"
                      autocomplete="off" maxlength="50" />
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Upload Profile</label>
                    <FileUpload :disabled="edituserloader" mode="basic" ref="userprofileimg"
                      class="custom-upload-btn-ultima" v-model="edit.userprofileimg"
                      v-on:change="handleUploaduserProfile()" />
                    <div class="custom-error" v-if="errorprofileimage">{{ errorprofileimage }}</div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl == null">
                  <img v-if="edit.importprofilefile != null && edit.importprofilefile != ''" :src="edit.importprofilefile"
                    class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    user-photo-img
                                  " alt="" style="width: 72px; height: 72px;" />
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="exitpreviewurl != null">
                  <img :src="exitpreviewurl.objectURL" class="
                                    rounded
                                    bg-light
                                    me-auto
                                    d-block
                                    user-photo-img
                                  " alt="" style="width: 72px; height: 72px;" />
                </div>
              </div>
            </div>
            <div v-if="showloaderusermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="userEditStepFirstStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateuserDetailbtn()">
              <span v-if="!edituserloader">Next</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="edituserloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- First Stepper End Here -->
        <!-- Second Stepper Start Here -->
        <div class="modal-body modal-body-scroll-outer" v-if="userEditStepTwoStatus">
          <div style="position: relative;
    min-height: 443px;">
            <div v-if="!showloaderusermodal">
              <div class="row">
                <div class="col-lg-4 col-md-4 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Role Type<span class="text-danger">*</span></label>
                    <div class="custom-group-radio-box-btn">
                      <input type="radio" :disabled="edituserloader" class="btn-check" v-model="edituser.roletype"
                        name="role-option" id="rolewise" autocomplete="off" value="1" />
                      <label class="btn custom-radio-box-btn text-capitalize" for="rolewise">Role
                        Wise</label>
                      <input type="radio" :disabled="edituserloader" class="btn-check" v-model="edituser.roletype"
                        name="role-option" id="manualrole" autocomplete="off" value="2" />
                      <label class="btn text-capitalize" for="manualrole">Manual Permission</label>
                      <div class="custom-error" v-if="v$.edituser.roletype.$error">
                        {{ v$.edituser.roletype.$errors[0].$message }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 col-12" v-if="edituser.roletype == 1">
                  <div class="custom-form-group">
                    <label class="form-label">Role<span class="text-danger">*</span></label>

                    <Multiselect :disabled="edituserloader" v-model="edituser.userrole" :options="userrole_list"
                      :searchable="true" label="label" placeholder="Select User Role"
                      class="multiselect-custom text-capitalize" :canClear="true" :closeOnSelect="true" />
                    <div class="custom-error" v-if="v$.edituser.userrole.$error && edituser.roletype == 1">
                      {{ v$.edituser.userrole.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="edituser.roletype == 2">
                <div class="col-12">
                  <div class="flex flex-wrap gap-2 mb-2">
                    <button type="button" class="btn custom-view-detail-btn me-2" @click="expandAll()">
                      <i class="pi pi-plus me-2" style="font-size: 0.7rem"></i>Expand All
                    </button>
                    <button type="button" class="btn custom-view-detail-btn" @click="collapseAll()">
                      <i class="pi pi-minus me-2" style="font-size: 0.7rem"></i>Collapse All
                    </button>
                  </div>
                  <Tree :value="nodes" :disabled="edituserloader" selectionMode="checkbox"
                    v-model:selectionKeys="edituser.selectedTreeValue" :expandedKeys="expandedKeys">
                  </Tree>
                  <div class="custom-img-error" v-if="v$.edituser.selectedTreeValue.$error && edituser.roletype == 2">
                    {{ v$.edituser.selectedTreeValue.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showloaderusermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="userEditStepTwoStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateUserPermissionbtn()">
              <span v-if="!edituserloader">Next</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="edituserloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Second Stepper End Here -->
        <!-- Third Stepper Start Here -->
        <div class="modal-body modal-body-scroll-outer" v-if="userEditStepThrirdStatus">
          <div style="position: relative;
    min-height: 443px;">
            <div v-if="!showloaderusermodal">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                    <label class="from-label-value me-3">Allow To Show Mobile Number : </label>
                    <InputSwitch :disabled="editpermissionloader" v-model="allow.usermobpermission" />
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-12">
                  <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                    <label class="from-label-value me-3">Allow To Show Email Id : </label>
                    <InputSwitch :disabled="editpermissionloader" v-model="allow.useremailpermission" />
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-12">
                  <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                    <label class="from-label-value me-3">Allow To Use Mobile App : </label>
                    <InputSwitch :disabled="editpermissionloader" v-model="allow.usermobapppermission" />
                  </div>
                </div>

                <div class="col-lg-12 col-md-12 col-12">
                  <div class="d-flex align-items-center justify-content-between customer-label-group">
                    <label class="from-label-value me-3">Allow To Edit User Permission : </label>
                    <InputSwitch :disabled="editpermissionloader" v-model="allow.usereditpermission" />
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showloaderusermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="userEditStepThrirdStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateClientUserPermission()">
              <span v-if="!editpermissionloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="editpermissionloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Third Stepper End Here -->
      </div>
    </div>
  </div>
  <!-- Edit Details end here -->

  <!-- filter start here -->
  <div class="modal-mask" v-if="clientuserfiltermodals">
    <div class="
        modal-dialog modal-xs modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button type="button" class="btn-close" @click="userListfilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Mobile No.</label>
                    <input type="text" v-model="ad15" class="form-control" id="formMobileNoInput"
                      placeholder="Enter mobile Number" maxlength="10" @keypress="onlyNumber" autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">User Status</label>
                    <Multiselect v-model="ad44" :options="userStatusList" :searchable="false" label="label"
                      placeholder="Select Status" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn" @click="filterListUser(ad15, ad44)">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetUser">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
</template>
<script>
import commonMixin from "../../../mixins/commonMixin.js"
import ApiService from "../../../service/ApiService";
import { required, helpers, email, numeric, minLength, requiredIf, maxLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
  mixins: [commonMixin],
  data() {
    return {
      v$: useValidate(),
      clientUserList: null,
      totalRecords: 0,
      page_no: 0,
      userStatusList: [
        { value: 1, label: "Active" },
        { value: 2, label: "Temporary Suspended" },
        { value: 3, label: "Permanent Suspended" },
      ],
      userInfo: {},
      loading: false,
      clientuserfiltermodals: false,
      clientusermodals: false,
      userstatus: null,
      mobilenumber: "",
      userrole_list: [],
      searchuser: "",
      filterflag: false,
      filterButtonShow: false,
      dissableFilterButton: false,
      edit: {
        birthdate: null,
        designation: '',
        branch: [],
        importprofilefile: '',
        editHostId: '',
        userprofileimg: '',
        title: { value: 1, label: 'Mr.' },
        firstname: '',
        middlename: '',
        lastname: '',
        email: '',
        gender: '',
        primobile: "",
        countrycode: { value: 101, label: "+91" },
        fulladdress: '',
        state: { value: 11, label: "Goa" },
        city: "",
        area: "",
        pincode: '',
        landmark: '',
      },
      edituser: {
        roletype: '',
        userrole: "",
        selectedTreeValue: [],
      },
      rolesList: [],

      expandedKeys: {},
      userid: '',
      nodes: [],
      edituserloader: false,
      usermob: '',
      localmobno: '',
      searchfullname: '',
      searchnameloader: false,
      searchnamebtnactive: false,

      voterEditStepStatus: false,
      editpermissionloader: false,
      showloadervotermodal: false,
      allow: {
        usermobpermission: '',
        useremailpermission: '',
        useraadharpermission: '',
        usermobapppermission: '',
        usereditpermission: '',
      },
      alloweditvoterfk: '',
      unlockbtnloader: false,
      ad15: '',
      ad44: '',
      hidesearchname: false,
      titleList: [],
      createpreviewurl: null,
      exitpreviewurl: null,
      addpermissionloader: false,
      usertypeedit: '',
      usertypeadd: 1,
      hostId: '',
      existUserId: '',
      edituserdetailstatus: false,
      userEditStepFirstStatus: false,
      userEditStepTwoStatus: false,
      userEditStepThrirdStatus: false,
      showloaderusermodal: false,
      maxDateBirth: new Date(),
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      designationList: [],
      cityloaderflag: false,
      arealoaderflag: false,
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Other", value: 3 },
      ],
      stateList: [],
      cityList: [],
      areaList: [],
      errorprofileimage: '',
      client_info: '',
      userstorageimgpath: '',
      isShowMobileNo: "",
      isShowEmail: "",
      isAllowUserEdit: '',
      isUserLockStatus: '',
    };
  },

  mounted() {
    this.loading = true;
    this.getUserList({ ad23: 1 });
    this.expandAll();
    this.getModules();
    this.client_info = JSON.parse(localStorage.client_info);
    this.userstorageimgpath = this.client_info.maa24;
    this.user_info = JSON.parse(localStorage.user);
    this.user_storage_fk = this.user_info.user_id;
    this.alloweditvoterfk = this.user_info.ad42;
    this.isShowMobileNo = this.user_info.ad39;
    this.isShowEmail = this.user_info.ad40;
    this.isAllowUserEdit = this.user_info.ad42;
    this.isUserLockStatus = this.user_info.ad43;
  },

  ApiService: null,
  interval: null,

  created() {
    this.ApiService = new ApiService();
  },

  watch: {
    mobilenumber() {
      this.filterChanges();
    },
    userrole() {
      this.filterChanges();
    },
    userstatus() {
      this.filterChanges();
    },
  },
  validations() {
    return {
      edit: {
        primobile: {
          required: helpers.withMessage('Please enter mobile number', required), numeric: helpers.withMessage('Only numbers are allowed', numeric), minLength: helpers.withMessage(
            "Mobile no should be min 10 digit",
            minLength(10)
          ), maxLength: maxLength(10)
        },
        firstname: {
          required: helpers.withMessage("Please enter first name", required),
        },
        lastname: {
          required: helpers.withMessage("Please enter last name", required),
        },
        email: {
          email: helpers.withMessage('Email address is invalid', email)
        },
        birthdate: { required: helpers.withMessage('Please select birthdate', required) },
        gender: { required: helpers.withMessage('Please select gender', required) },
        designation: { required: helpers.withMessage('Please select designation', required) },
        branch: { required: helpers.withMessage('Please select branch', required) },
        fulladdress: { required: helpers.withMessage('Please enter full address', required) },
        state: { required: helpers.withMessage('Please select state', required) },
        city: { required: helpers.withMessage('Please select city', required) },
        area: { required: helpers.withMessage('Please select area', required) },
        pincode: {
          required: helpers.withMessage('Please enter pincode', required),
          minLength: helpers.withMessage(
            "Pincode should be min 6 length",
            minLength(6)
          ),
        },
      },
      edituser: {
        roletype: { required: helpers.withMessage("Please select role type", required), },
        userrole: {
          requiredIf: helpers.withMessage(
            "Please select role",
            requiredIf(
              this.edituser.roletype == 1
            )
          ),
        },
        selectedTreeValue: {
          requiredIf: helpers.withMessage(
            "Please select module",
            requiredIf(
              this.edituser.roletype == 2
            )
          ),
        },
      },

    };
  },
  methods: {
    getHostBranches(e) {
      this.ApiService.getHostBranches(e).then((data) => {
        if (data.status == 200) {
          this.branchList = data.data;
        } else {
          this.branchList = '';
        }
      });
    },
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },
    getUserDesignations(e) {
      this.ApiService.getUserDesignations(e).then((data) => {
        if (data.status == 200) {
          this.designationList = data.data;
        }
      });
    },
    getModules(ev) {
      this.ApiService.getModules(ev).then(data => {
        if (data.status == 200) {
          this.nodes = data.data;
        }
      })
    },
    getstates() {
      this.ApiService.getstates().then((data) => {
        if (data.success == true) {
          this.stateList = data.data;
          this.showloaderusermodal = false;
        } else {
          this.stateList = null;
          this.showloaderusermodal = false;
        }
      });
    },
    getCityByStateId(e) {
      this.cityloaderflag = true;
      this.edit.city = '';
      this.edit.area = '';
      this.ApiService.getcities({ state_id: e.value }).then((data) => {
        if (data.success == true) {
          this.cityList = data.data;
          this.cityloaderflag = false;
        } else {
          this.cityloaderflag = false;
          this.cityList = [];
        }
      });
    },
    getAreaByCityId(e) {
      this.arealoaderflag = true;
      this.edit.area = '';
      this.ApiService.getareas({ city_id: e.value }).then((data) => {
        if (data.success == true) {
          this.areaList = data.data;
          this.arealoaderflag = false;
        } else {
          this.arealoaderflag = false;
          this.areaList = [];
        }
      });
    },
    filterChanges() {
      if (
        this.mobilenumber != "" ||
        this.userrole != null ||
        this.userstatus != null
      ) {
        this.filterButtonShow = true;
      } else {
        this.filterButtonShow = false;
      }
    },

    getUserList(e) {
      this.loading = true;
      this.ApiService.getUserList(e).then((items) => {
        if (items.success == true) {
          this.clientUserList = items.data;
          this.totalRecords = items.count;
          this.loading = false;
          this.searchnameloader = false;
        } else {
          this.loading = false;
          this.clientUserList = null;
          this.totalRecords = 0;
          this.searchnameloader = false;
        }
      });
    },

    changePage(event) {
      this.page_no = event.page;
      this.getUserList({
        ad23: 1,
        ad15: this.ad15,
        ad44: this.ad44,
        page_no: this.page_no,
      });
    },
    userDetailEditModalOpen(e) {
      this.edituserdetailstatus = true;
      this.userEditStepFirstStatus = true;
      this.userEditStepTwoStatus = false;
      this.userEditStepThrirdStatus = false;
      this.showloaderusermodal = true;
      this.edit.gender = '';
      this.edit.birthdate = '';
      this.edituser.selectedTreeValue = "";
      this.edituser.userrole = "";
      this.edituser.roletype = '';
      this.createpreviewurl = null;
      this.exitpreviewurl = null;
      // this.getcountrycode();
      this.getalltitle();
      this.getUserDesignations();
      this.getRoles({ at4: 2 });
      this.getModules();
      this.existUserId = e.ad1;
      this.editHostId = e.ad2;
      this.getHostBranches({ ac1: this.editHostId });
      this.getstates();
      this.edit.primobile = e.ad15;
      this.edit.title = {
        label: e.ad5,
        value: e.ad4,
      };
      this.edit.firstname = e.ad6;
      this.edit.middlename = e.ad7;
      this.edit.lastname = e.ad8;
      this.edit.email = e.ad18;
      if (e.ad12 != null && e.ad12 != "") {
        this.edit.birthdate = new Date(e.ad12);
      }
      if (e.ad14 == 1) {
        this.edit.gender = {
          label: "Male",
          value: e.ad14,
        }
      } else if (e.ad14 == 2) {
        this.edit.gender = {
          label: "Female",
          value: e.ad14,
        }
      } else if (e.ad14 == 3) {
        this.edit.gender = {
          label: "Other",
          value: e.ad14,
        }
      }
      this.edit.designation = {
        label: e.ad11,
        value: e.ad10,
      };
      if (e.ad25 != null && e.ad25 != '') {
        var labels = e.ad25.split(',');
        var values = e.ad24.split(',');
        var branchesnames = [];
        for (var i = 0; i < labels.length; i++) {
          var tags = [];
          tags['label'] = labels[i];
          tags['value'] = values[i];
          Object.assign({}, tags);
          var tagobject = Object.assign({}, tags);
          branchesnames.push(tagobject);
        }
        this.edit.branch = branchesnames;
      }
      if (e.ad22 != null) {
        this.edit.importprofilefile = "https://storage.googleapis.com/" + this.userstorageimgpath + "/host/userprofile/" + e.ad22;
      }
      if (e.ad26 != null && e.ad26 == '') {
        this.edituser.roletype = 2;
      } else {
        this.edituser.roletype = 1;
      }
      this.edituser.userrole = e.ad26;
      if (e.ad29 != null) {
        let arrConverted = e.ad29.split(",");
        this.edituser.selectedTreeValue = arrConverted;
        for (let index = 0; index < arrConverted.length; index++) {
          let idxx = arrConverted[index];
          this.edituser.selectedTreeValue = {
            ...this.edituser.selectedTreeValue,
            [idxx]: { checked: true, partialChecked: false },
          };
        }
      }
      this.usertypeedit = e.ad23;
      this.edit.fulladdress = e.ad30;
      this.edit.pincode = e.ad37;
      this.edit.landmark = e.ad38;
      this.edit.state = {
        label: e.ad36,
        value: e.ad35,
      };
      this.edit.city = {
        label: e.ad34,
        value: e.ad33,
      };
      this.edit.area = {
        label: e.ad32,
        value: e.ad31,
      };
      if (e.ad39 == 1) {
        this.allow.usermobpermission = true;
      } else {
        this.allow.usermobpermission = false;
      }

      if (e.ad40 == 1) {
        this.allow.useremailpermission = true;
      } else {
        this.allow.useremailpermission = false;
      }

      if (e.ad41 == 1) {
        this.allow.usermobapppermission = true;
      } else {
        this.allow.usermobapppermission = false;
      }

      if (e.ad42 == 1) {
        this.allow.usereditpermission = true;
      } else {
        this.allow.usereditpermission = false;
      }
    },
    userDetailEditModalClose() {
      this.edituserdetailstatus = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
    },
    handleUploaduserProfile() {
      var file = this.$refs.userprofileimg.files[0];
      let allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
      if (!file.size > 2048) {
        this.errorprofileimage = 'File size cannot exceed more than 2MB';
      } else if (!file.name.match(allowedExtensions)) {
        this.errorprofileimage = 'Invalid file type';
      } else {
        this.errorprofileimage = '';
        this.edit.userprofileimg = this.$refs.userprofileimg.files[0];
        this.exitpreviewurl = this.edit.userprofileimg;
      }
    },
    updateuserDetailbtn() {
      this.v$.edit.$validate();
      var formData = new FormData();
      formData.append("ad1", this.existUserId);
      formData.append("ad2", this.editHostId);
      formData.append("ad15", this.edit.primobile);
      formData.append("ad4", this.edit.title.value);
      formData.append("ad5", this.edit.title.label);
      formData.append("ad6", this.edit.firstname);
      if (this.edit.middlename) {
        formData.append("ad7", this.edit.middlename);
      }
      formData.append("ad8", this.edit.lastname);
      var fullname = this.edit.title.label + ' ' + this.edit.firstname + ' ' + this.edit.middlename + ' ' + this.edit.lastname;
      formData.append('ad9', fullname);
      if (this.edit.email) {
        formData.append("ad18", this.edit.email);
      }
      formData.append('ad12', moment(this.edit.birthdate).format("YYYY-MM-DD"));
      formData.append("ad14", this.edit.gender.value);
      formData.append('ad30', this.edit.fulladdress);
      formData.append('ad37', this.edit.pincode);
      if (this.edit.landmark) {
        formData.append("ad38", this.edit.landmark);
      }
      formData.append("ad35", this.edit.state.value);
      formData.append("ad36", this.edit.state.label);
      formData.append("ad33", this.edit.city.value);
      formData.append("ad34", this.edit.city.label);
      formData.append("ad31", this.edit.area.value);
      formData.append("ad32", this.edit.area.label);
      formData.append("ad10", this.edit.designation.value);
      formData.append("ad11", this.edit.designation.label);
      if (this.edit.branch) {
        formData.append('branchlist', JSON.stringify(this.edit.branch));
      }
      if (this.edit.userprofileimg) {
        formData.append("userProfileImage", this.edit.userprofileimg);
      }
      formData.append('ad23', this.usertypeedit);
      // for (var pair of formData.entries()) {
      //     console.log(pair[0] + ' - ' + pair[1]);
      // }
      if (!this.v$.edit.$error) {
        this.edituserloader = true;
        this.ApiService.addOrUpdateUser(formData).then((data) => {
          if (data.status == 200) {
            this.edituserloader = false;
            var successMsg = data.message;
            this.$toast.open({
              message: successMsg,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.userEditStepFirstStatus = false;
            this.userEditStepTwoStatus = true;
          } else {
            var errorMsg = data.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.userEditStepFirstStatus = true;
            this.edituserloader = false;
          }
        });
      } else {
        this.$error;
      }
    },
    updateUserPermissionbtn() {
      this.v$.edituser.$validate();
      let fields = {};
      fields["ad1"] = this.existUserId;
      fields["ad2"] = this.editHostId;
      fields["ad15"] = this.edit.primobile;
      fields["ad6"] = this.edit.firstname;
      if (this.edituser.roletype) {
        fields["role_type"] = this.edituser.roletype;
      }
      if (this.edituser.roletype == 1) {
        fields["ad26"] = this.edituser.userrole;
      }
      if (this.edituser.roletype == 2) {
        fields["modules"] = this.edituser.selectedTreeValue;
      }

      if (!this.v$.edituser.$error) {
        this.edituserloader = true;
        this.ApiService.updateUserPermissions(fields).then((items) => {
          if (items.status == 200) {
            this.edituserloader = false;
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            this.userEditStepTwoStatus = false;
            this.userEditStepThrirdStatus = true;
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.edituserloader = false;
            this.userEditStepTwoStatus = true;
          }
        });
      }
    },
    updateClientUserPermission() {
      let fields = {};
      fields["ad1"] = this.existUserId;
      fields["ad2"] = this.editHostId;
      if (this.allow.usermobpermission == true) {
        fields["ad39"] = 1;
      } else if (this.allow.usermobpermission == false) {
        fields["ad39"] = 0;
      }

      if (this.allow.useremailpermission == true) {
        fields["ad40"] = 1;
      } else if (this.allow.useremailpermission == false) {
        fields["ad40"] = 0;
      }

      if (this.allow.usermobapppermission == true) {
        fields["ad41"] = 1;
      } else if (this.allow.usermobapppermission == false) {
        fields["ad41"] = 0;
      }

      if (this.allow.usereditpermission == true) {
        fields["ad42"] = 1;
      } else if (this.allow.usereditpermission == false) {
        fields["ad42"] = 0;
      }
      this.editpermissionloader = true;
      this.ApiService.updateuserallowpermissions(fields).then((items) => {
        if (items.status == 200) {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });

          this.userEditStepThrirdStatus = false;
          this.edituserdetailstatus = false;
          if (this.editHostId == this.user_storage_fk) {
            this.logout();
          } else {
            this.getUserList({ad23: 1});
          }
        } else {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    handleEditGender() {
      if (this.edit.title.value == 1) {
        this.edit.gender = {
          label: "Male",
          value: 1,
        }
      } else if (this.edit.title.value == 2 || this.edit.title.value == 3) {
        this.edit.gender = {
          label: "Female",
          value: 2,
        }
      } else {
        this.edit.gender = '';
      }
    },

    logout(e) {
      this.ApiService.logout(e).then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    userfilterModalOpen() {
      this.clientuserfiltermodals = true;
    },
    userListfilterModalClose() {
      this.clientuserfiltermodals = false;

    },

    filterClientUser() {
      let fields = {};
      this.showloader = true;
      this.ApiService.getUserList(fields).then((items) => {
        this.showloader = false;
        if (items.status == 200) {
          this.clientUserList = items.data;
          this.totalRecords = items.count;
        } else {
          this.clientUserList = null;
          this.totalRecords = 0;
        }
        this.$store.dispatch("clientUserFilterModalStatus", false);
        this.emitCustomEvent();
      });
    },

    getRoles(e) {
      this.ApiService.getRoles(e).then((data) => {
        if (data.status == 200) {
          this.userrole_list = data.data;
        }
      });
    },
    filterListUser(ad15, ad44) {
      this.clientuserfiltermodals = false;
      this.ad15 = ad15;
      this.ad44 = ad44;
      this.getUserList({
        ad23: 1,
        page_no: this.page_no,
        ad15: this.ad15,
        ad44: this.ad44,
      });
      this.filterflag = true;
    },

    resetUser() {
      this.filterflag = false;
      this.ad15 = "";
      this.ad44 = '';
      this.getUserList({ad23: 1});
    },

    // searchUserList(e) {
    //   this.getUserList({
    //     user_name: e,
    //   });
    // },
    searchFnameUser(searchfullname) {
      this.hidesearchname = true;
      this.searchnameloader = true;
      this.getUserList({
        ad23: 1,
        ad9: searchfullname,
      });
      this.searchnamebtnactive = true;
    },
    searchNameUserStop() {
      this.searchnamebtnactive = false;
      this.searchfullname = "";
      this.getUserList({ad23: 1});
      // this.searchnameloader = false;
      this.hidesearchname = false;
    },

    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        $event.preventDefault();
      }
    },
    expandAll() {
      for (let node of this.nodes) {
        this.expandNode(node);
      }

      this.expandedKeys = { ...this.expandedKeys };
    },

    collapseAll() {
      this.expandedKeys = {};
    },

    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;

        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    changelockstatus(e) {
      this.unlockbtnloader = true;
      this.ApiService.changeUserLockStatus({ ad1: e.ad1 }).then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.getUserList();
          this.unlockbtnloader = false;
        } else {
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.unlockbtnloader = false;
        }
      });
    },
    createVoterEditDesignation(event) {
      if (event && event.value) {
        if (Number.isInteger(event.value)) {
          this.edit.designation = { value: event.value, label: event.label };
        } else {
          this.edit.designation = { value: 0, label: event.label };
        }
      }
    },
  },
};
</script>

<style scoped>
.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.filter-apply-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  line-height: 13px;
  height: 34px;
}

.filter-reset-btn {
  letter-spacing: 0.26px;
  color: #5266e5;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.filter-reset-btn:focus-visible,
.filter-apply-btn:focus-visible {
  outline: 0;
}

.filter-reset-btn:focus,
.filter-apply-btn:focus {
  box-shadow: none;
}

.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-temporary-suspended {
  background: #fde3c8;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #bba691;
  line-height: 12px;
}

.status-permanently-suspended {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.divider-custom-list {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #d4e4f3;
}

.locked-user-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 16px;
  padding: 6px 12px;
  font-family: "AcuminPro-Regular";
  width: 97px;
}

.locked-user-btn:hover {
  box-shadow: none;
}

.custom-outline-lock-btn {
  background: #fde4e7 0% 0% no-repeat padding-box;
  border: 1px solid #dbb8b8;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-lock-btn .lock-btn-color {
  color: #c63737;
  font-size: 13px;
}

.branch-list {
  background: #ffd8b2;
  border-radius: 11px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.4rem 0.2em 0.4rem;
  text-transform: uppercase;
  border: 1px solid #c79b6f;
  line-height: 12px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.branch-multi-span {
  align-items: center;
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-wrap: wrap;
}
</style>